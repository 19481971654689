import {Component, Watch} from "vue-property-decorator"
import {VNode} from "vue"
import FormMixin from "@/mixins/FormMixin"
import Wizard from "@/components/Wizard/Wizard"
import ServiceStep1 from "@/_modules/service/components/ServiceStep1"
import ServiceStep2 from "@/_modules/service/components/ServiceStep2"
import ServiceStep3 from "@/_modules/service/components/ServiceStep3"
import ServiceStep4 from "@/_modules/service/components/ServiceStep4"
import {ServiceForm, ServiceValidation, Service} from "@/_modules/service/types";
import {Validations} from "vuelidate-property-decorators";
import {inferValidationInstance} from "@/utils/vuelidate-extension";
import {validationServiceRules} from "@/_modules/service/validation";
import {buildServiceForm} from "@/_modules/service/form-builder";
import {FileData, FilePublishStatePayload} from "@/types";
import {BasicErrorHandler} from "@/utils/errorHandler";
import * as Error from "@/utils/errors";
import {ErrorCodes} from "@/constants/APIconstants";
import {v4 as uuidv4} from "uuid";
import {authStore, serviceAndDemandStore} from "@/store";
import {servicesStore} from "@/_modules/service/store/services";
import {BIconArrowLeft, BIconArrowRight} from "bootstrap-vue";
import {profileStore} from "@/_modules/profile/store/profile";
import {isEmpty} from "@/constants/DataBoundaries";
import * as ActivityRoutes from "@/_modules/activity/router/routes";
import AttentionPopup from "@/components/layout/AttentionPopup";
import * as ProfileRoutes from "@/_modules/profile/router/routes";
import _ from "lodash";
import PageHeader from "@/components/layout/PageHeader"
import ContentSection from "@/components/layout/ContentSection"
import { CustomEvents } from "@/constants/ComponentEvents"
import { EventBus } from "@/main"

const firstStep = 0

const localeRoot = 'service'

export interface ServiceComponentValidation {
  form: ServiceValidation;
  //topLevelCategory: ValidationRuleSet;
}

export class ServiceErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.UserDoesNotHaveProfile:
        this.errorMessageKey = "err_user_does_not_have_profile"
        break
      case ErrorCodes.OperationIsNotAuthorized:
        this.errorMessageKey = "err_operation_is_not_authorized"
        break
      case ErrorCodes.UserDoesNotHaveIBAN:
        this.errorMessageKey = "err_user_does_not_have_iban"
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const serviceErrorHandler = new ServiceErrorHandler()

@Component({name: 'ServiceLayout', components: {BIconArrowLeft, BIconArrowRight}})
export default class ServiceLayout extends FormMixin {

  //private topLevelCategory = ''
  private form: ServiceForm = buildServiceForm()
  private files: FileData[] = []
  private showAttention: boolean = false
  public fileUploadInProgress = false
  private ibanExist: boolean | null = null

  @Validations()
  public validations(): ServiceComponentValidation {
    return validationServiceRules()
  }

  @Watch('form.providedAtClientPlace')
  public async preFillAddress(): Promise<void> {
    if (this.form.providedAtClientPlace) {
      const resp = await this.withRequest(profileStore.getProfile(authStore.authInfo!.userId!))
      if (isEmpty(this.form.location.address.address) && resp && resp!.location) {
        this.form.location = resp!.location!
      }
    }
  }

  public get serviceId(): string | undefined {
    return this.$route.params.serviceId
  }

  public async mounted(): Promise<void> {
    if (!this.serviceId) {
      const steps = _.cloneDeep({
        ...serviceAndDemandStore.serviceStepData1,
        ...serviceAndDemandStore.serviceStepData2,
        ...serviceAndDemandStore.serviceStepData3,
        ...serviceAndDemandStore.serviceStepData4
      })
      this.form = {
        ...buildServiceForm(),
        ...steps
      }
    }

    const userId = authStore.authInfo?.userId
    if (userId !== undefined) {
      try {
        const personalSettings = await this.withRequest(profileStore.getSettings(), serviceErrorHandler)
        this.ibanExist = personalSettings.payment?.iban !== undefined
        if (personalSettings.profile !== undefined && personalSettings.payment?.iban !== undefined) {
          this.form.serviceId = this.serviceId
          if (this.serviceId !== undefined) {
            const resp = await this.withRequest(servicesStore.getServiceById(this.serviceId), serviceErrorHandler)

            this.form = resp
            //this.form.visible = this.form.visible
            this.form.agreeToPublishAddress = resp.agreeToPublishAddress

            const uploadedFiles = await this.withRequest(servicesStore.getUploadedFiles(this.form.uploadToken as string), serviceErrorHandler)
            this.files.push(...uploadedFiles.map((obj: FileData) => obj))
          }
        }
      } catch (err) {
        //
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const profile = await this.withRequest(profileStore.getProfile(userId)).catch(e => Promise.resolve(undefined))
      this.showAttention = (profile === undefined) || (!this.ibanExist)
    }

    if (!this.form.uploadToken) {
      this.form.uploadToken = uuidv4()
    }
  }

  public beforeDestroy(): void {
    serviceAndDemandStore.clearServiceData()
    serviceAndDemandStore.setCurrentServiceStep(0)
  }

  private setDataToStore(): void {
    switch (serviceAndDemandStore.currentServiceStep) {
      case 1:
        if (this.validateFirstStep()) {
          serviceAndDemandStore.setServiceStep1({
            serviceId: this.form.serviceId,
            userId: this.form.userId,
            title: this.form.title,
            competences: this.form.competences,
            category: this.form.category,
            description_ru: this.form.description_ru,
            description_et: this.form.description_et,
            description_en: this.form.description_en,
            languages: this.form.languages,
            uploadToken: this.form.uploadToken,
            joinToGroup: this.form.joinToGroup
          })
        }
        break
      case 2:
        if (this.validateSecondStep()) {
          serviceAndDemandStore.setServiceStep2({
            clientAge: this.form.clientAge,
            clientSex: this.form.clientSex,
            clientWeight: this.form.clientWeight,
            clientMentalAbility: this.form.clientMentalAbility,
            clientPhysicalActivity: this.form.clientPhysicalActivity,
            clientDisease: this.form.clientDisease
          })
        }
        break
      case 3:
        if (this.validateThirdStep()) {
          serviceAndDemandStore.setServiceStep3({
            location: this.form.location,
            agreeToPublishAddress: this.form.agreeToPublishAddress,
            providedAtClientPlace: this.form.providedAtClientPlace
          })
        }
        break
      case 4:
        if (this.validateFourthStep()) {
          serviceAndDemandStore.setServiceStep4({
            rate: this.form.rate,
            scheduleInfo_ru: this.form.scheduleInfo_ru,
            scheduleInfo_et: this.form.scheduleInfo_et,
            scheduleInfo_en: this.form.scheduleInfo_en,
            visible: this.form.visible
          })
        }
        break
      default:
        break
    }
  }

  private scrollToTop(): void {
    const wizard = document.querySelector('.wizard')
    if (wizard !== null && wizard.getBoundingClientRect().top < 0) {
      wizard.scrollIntoView({behavior: "auto", block: "start"})
    }
  }

  private async onPrevClick(): Promise<void> {
    await this.touchSession()
    serviceAndDemandStore.setCurrentServiceStep(Math.max(serviceAndDemandStore.currentServiceStep - 1, 0))
    this.setDataToStore()
    this.scrollToTop()
  }

  private validateFirstStep(): boolean {
    const fieldsToValidate = [this.$v.form.title, this.$v.form.competences, this.$v.form.description_ru, this.$v.form.description_et, this.$v.form.description_en, this.$v.form.languages, this.$v.form.category]
    const res = fieldsToValidate.flatMap(field => this.checkValidation(field))
    return !res.some(field => !field)
  }

  private validateSecondStep(): boolean {
    const fieldsToValidate = [this.$v.form.clientAge, this.$v.form.clientSex, this.$v.form.clientWeight, this.$v.form.clientPhysicalActivity, this.$v.form.clientMentalAbility]
    const res = fieldsToValidate.flatMap(field => this.checkValidation(field))
    return !res.some(field => !field)

  }

  private validateThirdStep(): boolean {
    const fieldsToValidate = [this.$v.form.location, this.$v.form.agreeToPublishAddress]
    const res = fieldsToValidate.flatMap(field => this.checkValidation(field))
    return !res.some(field => !field)
  }

  private validateFourthStep(): boolean {
    const fieldsToValidate = [this.$v.form.rate, this.$v.form.scheduleInfo_ru, this.$v.form.scheduleInfo_et, this.$v.form.scheduleInfo_en]
    const res = fieldsToValidate.flatMap(field => this.checkValidation(field))
    return !res.some(field => !field)
  }

  private async onNextClick(): Promise<void> {
    await this.touchSession()
    switch (serviceAndDemandStore.currentServiceStep) {
      case 0:
        if (this.validateFirstStep()) {
          serviceAndDemandStore.setCurrentServiceStep(serviceAndDemandStore.currentServiceStep + 1)
          this.setDataToStore()
          this.scrollToTop()
        }
        break
      case 1:
        if (this.validateSecondStep()) {
          serviceAndDemandStore.setCurrentServiceStep(serviceAndDemandStore.currentServiceStep + 1)
          this.setDataToStore()
          this.scrollToTop()
        }
        break
      case 2:
        if (this.validateThirdStep()) {
          serviceAndDemandStore.setCurrentServiceStep(serviceAndDemandStore.currentServiceStep + 1)
          this.setDataToStore()
          this.scrollToTop()
        }
        break
      case 3:
        if (this.validateFourthStep()) {
          this.setDataToStore()
          await this.onSubmit()
          serviceAndDemandStore.setCurrentServiceStep(0)
          await this.$router.push(ActivityRoutes.Services)

          if (this.form.serviceId !== undefined) {
            this.toastMessagesPayload.successToastMessageKey = 'msg_service_updated'
          } else {
            this.toastMessagesPayload.successToastMessageKey = 'msg_service_added'
          }
    
          EventBus.$emit(CustomEvents.ToastMsg, this.toastMessagesPayload)
          EventBus.$on(CustomEvents.DismissToast, () => {
            this.toastMessagesPayload.successToastMessageKey = null
          })
        }
        break
      default:
        break
    }
  }

  public async onSubmit(): Promise<void> {
    if (this.checkValidation(this.$v)) {

      const service = this.form as Service

      service.visible = service.visible === undefined ? service.visible = true : service.visible

      if (this.form.serviceId !== undefined) {
        await this.withRequest(servicesStore.updateOffer(service), serviceErrorHandler)
      } else {
        await this.withRequest(servicesStore.addOffer(service), serviceErrorHandler)
      }
    }
  }

  private async changePublishState(payload: FilePublishStatePayload): Promise<void> {
    await this.withRequest(servicesStore.setFileVisibility(payload), serviceErrorHandler)
  }

  public formattedAttentionMessage(): VNode {
    return (
      <div>
        {this.ibanExist ? this.translation('msg_warning_new_service_profile_not_filled_up') : this.translation('msg_proposal_attention_iban_not_exist')}
      </div>
    )
  }

  public render(): VNode {
    const currentStep = serviceAndDemandStore.currentServiceStep
    const v = inferValidationInstance<ServiceComponentValidation>(this.$v)
    
    return (
      <b-container fluid="xl">
        <b-row>
          <PageHeader
            headerClass="mt-4 mb-6 mb-md-10"
            title={this.serviceId === undefined ? this.translation('title_add_service') : this.translation('title_edit_service')}
            wideHeader={true}
          >
            {this.serviceId === undefined &&
              <p>{this.translation('lead_add_service')}</p>
            }
          </PageHeader>
        </b-row>
        <Wizard localeRoot={localeRoot} currentStep={serviceAndDemandStore.currentServiceStep} />
        <ContentSection aria-live="true">
          {currentStep === 0 &&
            <ServiceStep1
              class="pb-md-1"
              v={v}
              value={this.form}
              files={this.files}
              localeRoot={localeRoot}
              //topLevelCategory={this.form.category}
              onSubCategoryChange={(c: string) => (this.form.category = c)}
              onTopCategoryChange={(cat: string) => (this.form.category = cat)}
              onFileAdd={(file: FileData) => this.files.push(file)}
              onAttachmentsError={(err: string) => (this.errorMessageKey = err)}
              onFileUploadInProgress={(p: boolean) => (this.fileUploadInProgress = p)}
              onChangePublishState={(payload: FilePublishStatePayload) => this.changePublishState(payload)}
            />
          }
          {currentStep === 1 &&
            <ServiceStep2
              localeRoot={localeRoot}
              value={this.form}
              v={v}
            />
          }
          {currentStep === 2 &&
            <ServiceStep3
              localeRoot={localeRoot}
              value={this.form}
              v={v}
            />
          }
          {currentStep === 3 &&
            <ServiceStep4
              class="pb-md-1"
              localeRoot={localeRoot}
              value={this.form}
              v={v}
              onCurrencyInputFormatted={(amount: string) => (this.form.rate.price.amount = amount)}
            />
          }
          <hr class="mt-1 mb-6 mt-md-6" />
          <div class="d-flex flex-column flex-sm-row justify-content-end">
            {currentStep !== firstStep &&
              <b-button
                class="mb-2 mb-sm-0 mr-sm-2"
                onClick={this.onPrevClick}
                variant="outline-primary"
                disabled={this.busy}
              >
                <b-icon-arrow-left aria-hidden="true" class="app-icon-lg mr-2" />
                {this.translation('shared.btn_prev')}
              </b-button>
            }
            <b-button
              onClick={this.onNextClick}
              variant="primary"
              disabled={this.busy}
            >
              {this.translation(currentStep !== 3 ? 'shared.btn_next' : 'btn_save')}
              <b-icon-arrow-right aria-hidden="true" class="app-icon-lg ml-2" />
            </b-button>
          </div>
        </ContentSection>
        <AttentionPopup
          message={this.formattedAttentionMessage}
          btnNextLabel={'btn_change_profile'}
          btnNextPath={ProfileRoutes.Profile.path}
          btnNext={true}
          btnBack={true}
          btnClose={false}
          show={this.showAttention}
        />
      </b-container>
    )
  }
}
