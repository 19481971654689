import {Component, Prop} from "vue-property-decorator";
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";
import {ServiceForm} from "@/_modules/service/types";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {ServiceComponentValidation} from "@/_modules/service/components/ServiceLayout";
import AddressComponent, {AddressTitleType} from "@/components/structures/Address";
import {isEmpty} from "@/constants/DataBoundaries";
import CheckBox from "@/components/form/CheckBox";

interface Props {
  readonly value: ServiceForm;
  readonly v: ValidationInstance<ServiceComponentValidation>;
  readonly localeRoot: string;
}

@Component({name: 'ServiceStep3'})
export default class ServiceStep3 extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: ServiceForm
  @Prop(Object) public readonly v!: ValidationInstance<ServiceComponentValidation>
  @Prop(String) public readonly localeRoot!: string


  public render(): VNode {
    return (
      <div>
        <h2 class="fieldset-title mb-2">{this.translation(`${this.localeRoot}.wizard.title_service_address`)}</h2>
        <div class="mb-4 mt-6 my-md-10">
          <AddressComponent
            v-model={this.value.location}
            v={this.v.form?.location}
            addressTitle={AddressTitleType.ServiceAddress}
            atClientPlace={this.value.providedAtClientPlace}
            onChangeServicePlace={(v: boolean) => {this.value.providedAtClientPlace = v}}
            servicePlaceTypeControl={true}
            addressAlreadyDefined={!Object.values(this.value.location.address).some((it) => {
              return isEmpty(it)
            })}
            isService={true}
          />
          <CheckBox
            class="mb-3"
            label={this.translation('fld_location_consent')}
            v-model={this.value.agreeToPublishAddress}
            invalid-message={this.buildInvalidMessage(this.v.form.agreeToPublishAddress!)}
          />
        </div>
      </div>
    )
  }
}
