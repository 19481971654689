import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import ServiceLayout from "@/_modules/service/components/ServiceLayout";
import {TsxComponent} from "@/components/TsxComponent";

@Component({name: 'NewService'})
export default class Service extends TsxComponent<{}> {

  public render(): VNode {
    return (
      <ServiceLayout />
    )
  }
}
