import {Component} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"
import { BIcon, BIconChatDots, BIconFileEarmarkText, BIconGeoAlt, BIconGeoAltFill, BIconPaperclip, BIconPatchQuestionFill, BIconStar, BIconTag } from "bootstrap-vue"

import Button from "@/components/form/Button"
import AttentionPopup from "@/components/layout/AttentionPopup"

import {centerMapOnLocation, coordsToLatLng, displayRoute, mapStyles} from "@/utils/map-utils"
import {calculatePriceWithPercentage, distanceFormatter, formatDate} from "@/utils/formatters"
import {Currency, FileData, Language, MapCoordinates, parseQueryParamCoordinates, RateType} from '@/types'
import * as RequestServiceRoutes from "@/_modules/request/router/request-service-routes"

import {ServiceDetails, ServiceDetailsPayload} from '@/_modules/service/types'
import {defaultMapCenter, disablePayedServices, foundZoom} from '@/constants/ApplicationConfiguration'
import {BackendUrls} from "@/constants/APIconstants"

import {configStore} from "@/store/config"
import {servicesStore} from "../store/services"
import {appStore, authStore} from "@/store"
import {mapStore} from "@/store/map"
import ServiceDetailsClientParameters from "@/_modules/service/components/ServiceDetailsClientParameters";
import MultiLangTabs from '@/components/MultiLangTabs'
import {profileStore} from "@/_modules/profile/store/profile";
import * as ProfileRoutes from "@/_modules/profile/router/routes";
import PageHeader from "@/components/layout/PageHeader"
import AppIconVerified from "@/components/icons/AppIconVerified"
import AppIconStar from "@/components/icons/AppIconStar"
import AppIconGenderAmbiguous from "@/components/icons/AppIconGenderAmbiguous"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import GmapCustomMarker from 'vue2-gmap-custom-marker/gmap-custom-marker';
import { isNumericEmpty } from "@/constants/DataBoundaries"
import ContentSection from "@/components/layout/ContentSection"
import { ServiceFind } from "../router/routes"

@Component({name: "ServiceDetails", components: {
  BIcon,
  BIconChatDots,
  BIconFileEarmarkText,
  BIconGeoAlt,
  BIconGeoAltFill,
  BIconPaperclip,
  BIconPatchQuestionFill,
  BIconStar,
  BIconTag
}})
export default class extends BackendClientMixin {

  private showAttention: boolean = false
  public files: FileData[] = []
  public serviceDetails: ServiceDetails | null = null

  public coords: MapCoordinates = defaultMapCenter

  public get isRelativeDemandExist(): boolean {
    return this.serviceDetails !== null && this.serviceDetails.myDemands?.length > 0
  }

  public get userLocation(): MapCoordinates | undefined {
    const currentLocation = parseQueryParamCoordinates(this.$route.query)
    if (currentLocation?.latitude === defaultMapCenter.latitude && currentLocation.longitude === defaultMapCenter.longitude) {
      return undefined
    } else {
      return currentLocation
    }
  }

  public async mounted(): Promise<void> {
    try {
      const serviceId = this.$route.params.serviceId
      const latitude = isNaN(Number(this.$route.query.latitude)) ? appStore.serviceCoords.latitude : Number(this.$route.query.latitude)
      const longitude = isNaN(Number(this.$route.query.longitude)) ? appStore.serviceCoords.longitude : Number(this.$route.query.longitude)

      const serviceDetailsPayload: ServiceDetailsPayload = {
        lat: latitude,
        lon: longitude,
        serviceId: serviceId
      }

      const serviceDetails = await this.withRequest(servicesStore.getOfferDetailsById(serviceDetailsPayload))
      this.serviceDetails = serviceDetails
      if (serviceDetails.service.uploadToken !== undefined) {
        this.files = await this.withRequest(servicesStore.getUploadedFiles(serviceDetails.service.uploadToken))
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No typescript declarations
      await this.$gmapApiPromiseLazy();

      const minimumServiceDistance = 50

      if (this.serviceDetails?.distanceInMeters < minimumServiceDistance) {
        centerMapOnLocation(serviceDetails.service.location.coordinates, this.$refs.mapRef)
      } else {
        if (this.userLocation === undefined) {
          if (this.serviceDetails.service.providedAtClientPlace) {
            displayRoute(serviceDetails.service.location.coordinates, appStore.serviceCoords, this.$refs.mapRef)
  
          } else {
            displayRoute(appStore.serviceCoords, serviceDetails.service.location.coordinates, this.$refs.mapRef)
          }
        }
  
        if (!disablePayedServices && this.userLocation !== undefined) {
          if (this.serviceDetails.service.providedAtClientPlace) {
            displayRoute(serviceDetails.service.location.coordinates, this.userLocation, this.$refs.mapRef)
  
          } else {
            displayRoute(this.userLocation, serviceDetails.service.location.coordinates, this.$refs.mapRef)
          }
        }
      }
    }
    catch (err) {
      this.errorMessageKey = "err_unexpected_error"
    }
  }

  protected get isOwnRequest(): boolean {
    return this.serviceDetails?.service.userId === authStore.authInfo?.userId
  }

  public get formattedPrice(): string {
    const rate = {
      type: this.serviceDetails?.service.rate.type,
      price: {
        currency: Currency.EUR,
        amount: calculatePriceWithPercentage(configStore.serverConfig, this.serviceDetails?.service.rate.price)
      }
    }
    return `${rate?.price.amount} ${this.translation(`enum_currency_${rate?.price.currency}`)}${rate?.type === RateType.HOURLY_PAYMENT ? this.translation('text_hour_price') : ''}`
  }

  public formattedAttentionMessage(): VNode {
    return (
      <div>
        {(this.isOwnRequest
          ? <span>{this.translation('msg_attention_attempt_to_request_for_own_service')}</span>
          : <span>{this.translation('msg_request_attention_user_does_not_have_profile')}</span>
        )}
      </div>
    )
  }

  public buildHeader(): VNode {
    return (
      <b-row>
        {this.serviceDetails && 
          <PageHeader
            headerClass="mt-4 mb-10"
            leadClass="text-sm"
            title={this.serviceDetails.service.title}
            wideHeader={true}
          >
            {this.translation('lbl_service_created')}: {formatDate(this.serviceDetails.dateCreated)}
          </PageHeader>
        }
      </b-row>
    )
  }

  public async toRequestCreate(demandId?: string): Promise<void> {

    const profile = await this.withRequest(profileStore.getProfile(authStore.authInfo!.userId))
    if (profile !== undefined && !this.isOwnRequest) {
      if (Object.keys(this.$route.query).length > 0) {
        this.coords = {
          latitude: Number(this.$route.query.latitude),
          longitude: Number(this.$route.query.longitude)
        }
        appStore.setServiceDetailsCoords(this.coords)
      }

      if (demandId === undefined) {
        this.$router.push({
          path: `${RequestServiceRoutes.RequestServiceDraft.path}/${this.$route.params.serviceId}`
        })
      } else {
        this.$router.push({
          path: `${RequestServiceRoutes.RequestServiceDraft.path}/${this.$route.params.serviceId}`,
          query: {
            demandId: demandId
          }
        })
      }
    } else {
      this.showAttention = true
    }
  }

  public buildMainSection(): VNode {
    return (
      <div class="map-section">
        <b-row>
          <b-col cols="12" md="7" lg="8">
            <div class="embed-responsive embed-responsive-4by3 h-100">
              <gmap-map
                ref="mapRef"
                center={this.serviceDetails === null ? coordsToLatLng(mapStore.lastPosition) : coordsToLatLng(this.serviceDetails.service.location.coordinates)}
                zoom={foundZoom}
                class="embed-responsive-item"
                options={{
                  scrollwheel: false,
                  minZoom: 6,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  styles: mapStyles
                }}
              >
                {this.serviceDetails !== null && this.userLocation !== undefined && disablePayedServices &&
                <gmap-marker
                  position={coordsToLatLng(this.userLocation)}
                />
                }
                {this.serviceDetails !== null && this.userLocation !== undefined && !disablePayedServices &&
                <GmapCustomMarker
                  alignment="center"
                  marker={coordsToLatLng(this.userLocation)}
                >
                  <b-img src={require('@/assets/images/icons/marker_home.svg')} />
                </GmapCustomMarker>
                }
                {this.serviceDetails?.service.location.coordinates !== null && !disablePayedServices &&
                <GmapCustomMarker
                  alignment="center"
                  marker={coordsToLatLng(this.serviceDetails!.service.location.coordinates)}
                >
                  <b-badge
                    href="#"
                    class="app-gmap-custom-marker"
                    variant="primary"
                    pill
                  >
                    {distanceFormatter(this.serviceDetails!.distanceInMeters, this.translation('text_m'), this.translation('text_km'))}
                  </b-badge>
                </GmapCustomMarker>
                }
              </gmap-map>
            </div>
          </b-col>
          <b-col cols="12" md="5" lg="4">
            <div class="p-6 pl-md-0">
              <p class="mb-0 h6">{this.translation('title_service_provider')}</p>
              <h2 class="mt-0 mb-3">{this.serviceDetails?.provider.name?.first} {this.serviceDetails?.provider.name?.last[0]}.</h2>
              <div class="d-flex mb-4">
                {this.serviceDetails?.provider.verified &&
                  <span class="small text-nowrap mr-4 d-flex align-items-center">
                    <AppIconVerified class="app-icon-lg mt-0 align-top mr-2 text-success" />
                    {this.translation('msg_param_verified_true')}
                  </span>
                }
                {!this.serviceDetails?.provider.verified &&
                  <span class="small text-nowrap mr-4 d-flex align-items-center">
                    <b-icon-patch-question-fill class="app-icon-lg mt-0 align-top mr-2" variant="danger-dark" />
                    {this.translation('msg_param_verified_false')}
                  </span>
                }
                {(!isNumericEmpty(this.serviceDetails?.provider.rating.ratingsCount) && this.serviceDetails!.provider.rating.ratingsCount !== 0) &&
                  <span class="small text-nowrap d-flex align-items-center">
                    <AppIconStar class="app-icon-lg mt-0 align-top mr-2 text-warning" aria-label={this.translation('lbl_rating')} />
                    {this.serviceDetails?.provider.rating.grade} ({this.serviceDetails?.provider.rating.ratingsCount})
                  </span>
                }
                {(isNumericEmpty(this.serviceDetails?.provider.rating.ratingsCount) || this.serviceDetails?.provider.rating.ratingsCount === 0) &&
                  <span class="small text-nowrap d-flex align-items-center">
                    <b-icon-star class="app-icon-lg mt-0 align-top mr-2 text-gray-400" aria-hidden={true} />
                    {this.translation('lbl_no_rating')}
                  </span>
                }
              </div>
              <hr class="w-100 mt-0 mb-6" />
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={this.serviceDetails?.service.providedAtClientPlace ? 'geo-alt-fill' : 'geo-alt'} class={`app-icon-lg align-top mt-0${this.serviceDetails?.service.providedAtClientPlace ? ' text-success' : ' text-gray-500'}`} aria-hidden={true} />
                </div>
                <div>
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_distance')}</p>
                  <span>{distanceFormatter(this.serviceDetails?.distanceInMeters, this.translation('text_m'), this.translation('text_km'))}. {this.serviceDetails?.service.providedAtClientPlace ? this.translation('msg_service_at_requester_location') : this.translation('msg_service_at_provider_location')}</span>
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                </div>
                <div>
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_languages')}</p>
                  <span>
                    {this.serviceDetails?.service.languages.map((lang, index) => {
                      return (
                        lang === Language.ASE ?
                          <span>
                            <b-link class="text-primary" v-b-tooltip={{title: this.translation('enum_language_user_speak_ase')}}>
                              {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}
                            </b-link>{index !== this.serviceDetails!.service.languages.length - 1 ? ', ' : ''}
                          </span> :
                          <span>
                            {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}{index !== this.serviceDetails!.service.languages.length - 1 ? ', ' : ''}
                          </span>
                      )
                    })}
                  </span>
                </div>
              </div>
              {this.serviceDetails?.provider.sex &&
                <div class="d-flex mb-4">
                  <div class="flex-grow-0 mr-3">
                    <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                  </div>
                  <div>
                    <p class="small mb-0 font-weight-bold">{this.translation('lbl_sex')}</p>
                    <span>{this.translation(`enum_sex_type_${this.serviceDetails?.provider.sex.toLowerCase()}`)}</span>
                  </div>
                </div>
              }
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={'file-earmark-text'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                </div>
                <div>
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_qualification')}</p>
                  <span>{this.translation(`enum_qualification_grade_${this.serviceDetails?.service.qualification}`)}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                </div>
                <div>
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_service_price')}</p>
                  <span>{this.formattedPrice}</span>
                </div>
              </div>
              {!this.isRelativeDemandExist &&
                <Button
                  variant="primary"
                  class="w-100 mt-6"
                  label={this.translation("btn_use_service_new")}
                  onClick={() => this.toRequestCreate()}
                />
              }
              {this.isRelativeDemandExist &&
                <b-dropdown
                  class="w-100 mt-6"
                  menu-class="shadow"
                  right
                  text={this.translation('btn_use_service')}
                  toggle-class="justify-content-center btn-start-query"
                  variant="primary"
                >
                  <b-dropdown-item onClick={() => this.toRequestCreate()}>
                    {this.translation('btn_use_service_new')}
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-text>
                    <span class="small text-nowrap">{this.translation('lbl_request_pre_filling_description')}</span>
                  </b-dropdown-text>
                  <b-dropdown-divider />
                  {this.serviceDetails?.myDemands.map((it) => {
                    return (
                      <b-dropdown-item onClick={() => this.toRequestCreate(it.id)}>
                        {it.name}
                      </b-dropdown-item>
                    )
                  })}
                </b-dropdown>
              }
            </div>
          </b-col>
        </b-row>
      </div>
    )
  }

  public buildDescriptionSection(): VNode {
    const scheduleInfo = {
      scheduleInfo_ru: this.serviceDetails?.service?.scheduleInfo_ru,
      scheduleInfo_et: this.serviceDetails?.service?.scheduleInfo_et,
      scheduleInfo_en: this.serviceDetails?.service.scheduleInfo_en
    }
    const description = {
      description_ru: this.serviceDetails?.service?.description_ru,
      description_et: this.serviceDetails?.service?.description_et,
      description_en: this.serviceDetails?.service?.description_en
    }
    return (
      <b-row class="justify-content-center mt-10 mt-md-16">
        {this.serviceDetails !== null &&
          <b-col cols="12" lg="10">
            <ContentSection>
              <div class="mb-2 mb-sm-0">
                <h2 class="fieldset-title">{this.translation('fld_service_working_hours')}</h2>
                <div class="pl-md-4">
                  <MultiLangTabs
                    value={{
                      scheduleInfo_ru: scheduleInfo.scheduleInfo_ru,
                      scheduleInfo_et: scheduleInfo.scheduleInfo_et,
                      scheduleInfo_en: scheduleInfo.scheduleInfo_en
                    }}
                  />
                </div>
                <h2 class="fieldset-title mt-6 mt-md-8">{this.translation('fld_service_description')}</h2>
                <div class="pl-md-4">
                  <MultiLangTabs
                    value={{
                      description_ru: description.description_ru,
                      description_et: description.description_et,
                      description_en: description.description_en
                    }}
                  />
                </div>
                <ServiceDetailsClientParameters
                  value={this.serviceDetails.service}
                  optionsWithCategoryName={this.serviceDetails.competencesWithParent}
                />
                {this.files.length > 0 && 
                  <h2 class="fieldset-title">{this.translation('title_service_certificate')}</h2>
                }
                {this.files.length > 0 && 
                  <div class="pl-md-4">
                    {!this.busy && this.files!.map(file => {
                      return file.public ? (
                        <div class="mt-2">
                          <b-link class="d-flex py-1 text-primary" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(file.id)}`}>
                            <b-icon-paperclip class="app-icon-lg mr-2"/>
                            {file.fileName}
                          </b-link>
                        </div>
                      ) : ''
                    })}
                  </div>
                }
              </div>
            </ContentSection>
          </b-col>
        }
      </b-row>
    )
  }

  public render(): VNode {
    return (
      <div>
        <b-container fluid="xl" class="service-details">
          {this.errorMessageKey === null && this.serviceDetails &&
            <div>
              {this.buildHeader()}
              {this.buildMainSection()}
              {this.buildDescriptionSection()}
            </div>
          }
          <AttentionPopup
            titleKey={this.isOwnRequest ? 'msg_attention_attempt_to_request_for_own_service' : 'msg_request_attention_user_does_not_have_profile'}
            btnNextLabel={!this.isOwnRequest ? 'btn_go_to_profile_page' : 'btn_to_search'}
            btnNextPath={!this.isOwnRequest ? ProfileRoutes.Profile.path : ServiceFind.path}
            btnNext={true}
            btnBack={false}
            btnClose={true}
            show={this.showAttention}
            onCancel={() => (this.showAttention = false)}
          />
        </b-container>
      </div>
    )
  }
}
