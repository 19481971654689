import {Component, Prop} from "vue-property-decorator";
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";
import {ServiceForm} from "@/_modules/service/types";
import I18nOptions from "@/utils/I18nOptions";
import {ClientAge, ClientDisease, ClientMentalAbility, ClientPhysicalActivity, ClientWeight, Sex} from "@/types";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {ServiceComponentValidation} from "@/_modules/service/components/ServiceLayout";
import CheckBoxGroupInput from "@/components/form/CheckBoxGroupInput";
import CheckboxMultiSelectInput from "@/components/form/CheckboxMultiSelectInput";

interface Props {
  localeRoot: string;
  value: ServiceForm;
  readonly v: ValidationInstance<ServiceComponentValidation>;
}
@Component({name: 'ServiceStep2'})
export default class ServiceStep2 extends TsxComponent<Props> {
  @Prop(String) public readonly localeRoot!: string
  @Prop(Object) public readonly value!: ServiceForm
  @Prop(Object) public readonly v!: ValidationInstance<ServiceComponentValidation>

  public render(): VNode {
    return (
      <div>
        <h2 class="fieldset-title mb-2">{this.translation(`${this.localeRoot}.wizard.title_details`)}</h2>
        <p class="mb-6 mb-md-10">
          {this.translation(`${this.localeRoot}.subtitle_clients_parameters`)}
        </p>
        <b-row class="mb-4 mb-md-10">
          <b-col cols="12" md="6">
            <CheckboxMultiSelectInput
              label={`${this.translation(`shared.lbl-client-age`)}*`}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientAge), 'shared.enum-client-age-', true)}
              value={this.value.clientAge}
              onInput={(v: ClientAge[]) => (this.value.clientAge = v)}
              invalidMessage={this.buildInvalidMessage(this.v.form.clientAge)}
            />
          </b-col>
          <b-col cols="12" md="6">
            <CheckBoxGroupInput
              checkBoxClass="pt-md-3"
              checkBoxGroupClass="mb-3"
              label={`${this.translation(`shared.lbl-client-sex`)}*`}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Sex), 'shared.enum-sex-type-', true)}
              v-model={this.value.clientSex}
              invalidMessage={this.buildInvalidMessage(this.v.form.clientSex)}
            />
          </b-col>
          <b-col cols="12" md="6">
            <CheckboxMultiSelectInput
              label={`${this.translation(`shared.lbl-weight`)}*`}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientWeight), 'shared.enum-client-weight-', true)}
              value={this.value.clientWeight}
              onInput={(v: ClientWeight[]) => (this.value.clientWeight = v)}
              invalidMessage={this.buildInvalidMessage(this.v.form.clientWeight)}
            />
          </b-col>
          <b-col cols="12" md="6">
            <CheckboxMultiSelectInput
              label={`${this.translation(`shared.lbl-client-mental-ability`)}*`}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientMentalAbility), 'shared.enum-client-mental-ability-', true)}
              value={this.value.clientMentalAbility}
              onInput={(v: ClientMentalAbility[]) => (this.value.clientMentalAbility = v)}
              invalidMessage={this.buildInvalidMessage(this.v.form.clientMentalAbility)}
            />
          </b-col>
          <b-col cols="12" md="6">
            <CheckboxMultiSelectInput
              label={`${this.translation(`shared.lbl-client-physical-activity`)}*`}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientPhysicalActivity), 'shared.enum-client-physical-activity-', true)}
              value={this.value.clientPhysicalActivity}
              onInput={(v: ClientPhysicalActivity[]) => (this.value.clientPhysicalActivity = v)}
              invalidMessage={this.buildInvalidMessage(this.v.form.clientPhysicalActivity)}
            />
          </b-col>
          <b-col cols="12" md="6">
            <CheckboxMultiSelectInput
              searchable
              label={this.translation(`shared.lbl-client-disease`)}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientDisease), 'shared.enum-client-disease-', true)}
              value={this.value.clientDisease}
              onInput={(v: ClientDisease[]) => (this.value.clientDisease = v)}
            />
          </b-col>
        </b-row>
      </div>
    )
  }
}
