import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import VueMultiSelect from "vue-multiselect"
import ApplicationConfiguration from "@/constants/ApplicationConfiguration";
import TextInput from "@/components/form/TextInput";
import {ServiceForm, ServiceType} from "@/_modules/service/types";
import {TsxComponent} from "@/components/TsxComponent";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {getAllSubCategories, getRelativeSubCategories} from "@/utils/formatters";
import {getServiceTranslationPropertyName, getServiceTypesSorted, getServiceTypeTranslation, getServiceTypeTranslationByKey} from "@/_modules/service/store/services";
import {appStore} from "@/store";
import {isEmpty} from "@/constants/DataBoundaries";
import {ServiceComponentValidation} from "@/_modules/service/components/ServiceLayout";
import TextAreaInput from "@/components/form/TextAreaInput";
import CheckBoxGroupInput from "@/components/form/CheckBoxGroupInput";
import I18nOptions from "@/utils/I18nOptions";
import {FileData, FilePublishStatePayload, Language} from "@/types";
import Attachments from "@/components/Attachments";
import {BIconCheckCircle, BIconXCircle} from "bootstrap-vue";
import ToolTip from '@/components/layout/ToolTip'
import CheckBox from '@/components/form/CheckBox'
import SubCategorySelectModal from "@/components/form/SubCategorySelectModal";

interface Props {
  readonly localeRoot: string;
  readonly files: FileData[];
  readonly value: ServiceForm;
  readonly v: ValidationInstance<ServiceComponentValidation>;
  //readonly topLevelCategory: string;
  readonly onSubCategoryChange: (v: string) => void;
  readonly onTopCategoryChange: (v: string) => void;
  readonly onFileAdd: (file: FileData) => void;
  readonly onAttachmentsError: (err: string) => void;
  readonly onFileUploadInProgress: (v: boolean) => void;
  readonly onChangePublishState: (payload: FilePublishStatePayload) => void;
}

@Component({name: 'ServiceStep1', components: {BIconCheckCircle, BIconXCircle}})
export default class ServiceStep1 extends TsxComponent<Props> {
  @Prop(String) public readonly localeRoot!: string
  @Prop(Object) public readonly value!: ServiceForm
  @Prop(Object) public readonly v!: ValidationInstance<ServiceComponentValidation>
  //@Prop(String) public readonly topLevelCategory!: string
  @Prop(Array) public readonly files!: FileData[]

  public get serviceTypeOptions(): ServiceType[] {
    return getServiceTypesSorted(appStore.locale, true)
  }

  public render(): VNode {
    return (
      <div>
        <h2 class="fieldset-title mb-2">{this.translation(`${this.localeRoot}.wizard.title_basic_info`)}</h2>
        <p class="mb-6 mb-md-10">
          {this.translation(`${this.localeRoot}.subtitle_fill_up_basic_info`)}
        </p>
        <b-row>
          <b-col cols="12" md="4">
            <TextInput
              class={"text-input-area"}
              maxLength={ApplicationConfiguration.defaultInputMaxLength}
              label={`${this.translation('service.fld-service-skill')}*`}
              placeholder={this.translation('service.fld-service-skill-placeholder')}
              v-model={this.value.title}
              invalid-message={this.buildInvalidMessage(this.v.form.title)}
            />
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label={`${this.translation('fld_service_category')}*`}
              state={this.buildInvalidMessage(this.v.form.category) !== undefined ? false : undefined}
              invalid-feedback={this.buildInvalidMessage(this.v.form.category)}
            >
              <VueMultiSelect
                class="custom-control-single-select"
                close-on-select={true}
                label={getServiceTranslationPropertyName(appStore.locale)}
                multiple={false}
                options={this.serviceTypeOptions}
                placeholder=""
                preselect-first={true}
                searchable={false}
                show-labels={false}
                value={this.serviceTypeOptions.find(item => item.category === this.value.category)}
                track-by="category"
                onInput={(value: ServiceType) => {
                  this.$emit('topCategoryChange', value.category)
                  this.value.competences = []
                }}
                scopedSlots={{
                  singleLabel: ({ option }: { option: ServiceType }): VNode => {
                    return (
                      <span>{option.category === '' ? getServiceTypeTranslation(appStore.locale, option) : getServiceTypeTranslationByKey(appStore.locale, option.category)}</span>
                    )
                  }
                }}
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <SubCategorySelectModal
              label={`${this.translation('service.fld-type-of-service-delivery')}*`}
              invalid-message={this.buildInvalidMessage(this.v.form.competences)}
              class="font-weight-normal"
              options={isEmpty(this.value.category) ? getAllSubCategories(appStore.locale) : getRelativeSubCategories(this.value.category, appStore.locale)}
              value={this.value.competences}
              onConfirm={(v: string[]) => {
                this.value.competences = v
              }}
              disabled={isEmpty(this.value.category)}
              buttonLabel={isEmpty(this.value.category) ? this.translation('placeholder_choose_service_category') : (this.value.competences.length !== 0 ? this.translation(`shared.selected_count_alt`, [this.value.competences!.length]) : '')}
              buttonClass={'font-weight-normal'}
              modalTitle={this.translation('modal_title_choose_service_types', [getServiceTypeTranslationByKey(appStore.locale, this.value.category)])}
            />
          </b-col>
        </b-row>
        <div class="multi-tab-textarea">
          <fieldset>
            <legend class="col-form-label py-0">{this.translation('fld_my_service_description')}*</legend>
            <p class="small">{this.translation('fld_service_description_desc')}</p>
            <b-tabs nav-class="border-bottom-0">
              <b-tab>
                <template slot="title">
                  {this.translation('text_EST')}
                  {!isEmpty(this.value.description_et) &&
                    <b-icon-check-circle aria-hidden="true" class="ml-2" variant="primary" />
                  }
                </template>
                <TextAreaInput
                  maxLength={2000}
                  textCounter
                  v-model={this.value.description_et}
                  invalid-message={this.buildInvalidMessage(this.v.form.description_et)}
                />
              </b-tab>
              <b-tab>
                <template slot="title">
                  {this.translation('text_RUS')}
                  {!isEmpty(this.value.description_ru) &&
                    <b-icon-check-circle aria-hidden="true" class="ml-2" variant="primary" />
                  }
                </template>
                <TextAreaInput
                  maxLength={2000}
                  textCounter
                  v-model={this.value.description_ru}
                  invalid-message={this.buildInvalidMessage(this.v.form.description_ru)}
                />
              </b-tab>
              <b-tab>
                <template slot="title">
                  {this.translation('text_ENG')}
                  {!isEmpty(this.value.description_en) &&
                    <b-icon-check-circle aria-hidden="true" class="ml-2" variant="primary" />
                  }
                </template>
                <TextAreaInput
                  maxLength={2000}
                  textCounter
                  v-model={this.value.description_en}
                  invalid-message={this.buildInvalidMessage(this.v.form.description_en)}
                />
              </b-tab>
            </b-tabs>
          </fieldset>
        </div>
        <CheckBoxGroupInput
          label={`${this.translation('fld_profile_settings_work_language')}*`}
          v-model={this.value.languages}
          options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Language), 'enum_language_user_speak_')}
          invalid-message={this.buildInvalidMessage(this.v.form.languages)}
        />
        <fieldset class="mb-6 mb-md-10">
          <legend class="col-form-label py-0">{this.translation('fld_qualification_or_certificates')}</legend>
          <p class="small">{this.translation('msg_attachments_public_status_description')}</p>
          <Attachments
            value={this.files}
            placeholder={this.translation('fld_drag_and_drop_file')}
            upload-token={this.value.uploadToken}
            onChangePublishState={(payload: FilePublishStatePayload) => this.$emit('changePublishState', payload)}
            onInput={(file: FileData) => this.$emit('fileAdd', file)}
            onFileUploadInProgress={(v: boolean) => this.$emit('fileUploadInProgress', v)}
            onAttachmentsError={(err: string) => this.$emit('attachmentsError', err)}
          />
        </fieldset>
        <CheckBox
          v-model={this.value.joinToGroup}
        >
          <template slot='label'>
            {this.translation('shared.lbl-join-to-group')}&nbsp;
            <ToolTip
              variant="primary"
              options={{title: this.translation('shared.tooltip-join-to-group')}}
            />
          </template>
        </CheckBox>
      </div>
    )
  }
}
