import {defaultCountry} from "@/constants/ApplicationConfiguration";
import {mapStore} from "@/store/map";
import {Currency, ReusefulSearchFields} from "@/types";
import {ServiceForm} from "@/_modules/service/types";
import {buildReusefulSearchFields} from "@/_modules/shared-builders";

export interface ServiceFindForm extends ReusefulSearchFields {
  online: boolean;
  totalNumberServices: number;
}

export function buildServiceForm(): ServiceForm {
  return {
    userId: undefined,
    serviceId: undefined,
    category: '',
    competences: [],
    title: undefined,
    description_ru: undefined,
    description_et: undefined,
    description_en: undefined,
    languages: [],
    scheduleInfo_ru: undefined,
    scheduleInfo_et: undefined,
    scheduleInfo_en: undefined,
    qualification: undefined,
    location: {
      address: {
        country: defaultCountry
      },
      coordinates: mapStore.lastPosition
    },
    rate: {
      price: {
        amount: undefined,
        currency: Currency.EUR
      }
    },
    visible: true,
    uploadToken: undefined,
    providedAtClientPlace: undefined,
    agreeToPublishAddress: undefined,
    clientSex: [],
    clientAge: [],
    clientWeight: [],
    clientMentalAbility: [],
    clientPhysicalActivity: [],
    clientDisease: [],
    joinToGroup: undefined
  }
}

export function buildServiceFindForm(): ServiceFindForm {
  return {
    ...buildReusefulSearchFields(),
    online: false,
    totalNumberServices: 0
  }
}
