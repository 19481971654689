import {ServiceComponentValidation} from "@/_modules/service/components/ServiceLayout";
import {required, requiredIf, sameAs} from "vuelidate/lib/validators";
import DataBoundaries, {isEmpty, nonNegativeCurrencyValidator} from "@/constants/DataBoundaries";
import {buildValidationRules} from "@/utils/vuelidate-extension";
import {AddressValidation, GeoLocationValidation} from "@/types";
import {ServiceForm} from "@/_modules/service/types";

function addressValidation(): AddressValidation {
  return {
    country: {required},
    cityOrCounty: {required, ...buildValidationRules(DataBoundaries.city)},
    zipCode: {required, ...buildValidationRules(DataBoundaries.zipCode)},
    address: {required, ...buildValidationRules(DataBoundaries.address)}
  }
}

function locationValidation(): GeoLocationValidation {
  return {
    address: addressValidation(),
    coordinates: {}
  }
}

export function validationServiceRules(): ServiceComponentValidation {
  return {
    //topLevelCategory: {required},
    form: {
      serviceId: {},
      userId: {},
      qualification: {},
      competences: {required},
      category: {required},
      title: {required},
      description_ru: {required: requiredIf((obj: ServiceForm) => (isEmpty(obj.description_et) && isEmpty(obj.description_en)))},
      description_et: {required: requiredIf((obj: ServiceForm) => (isEmpty(obj.description_ru) && isEmpty(obj.description_en)))},
      description_en: {required: requiredIf((obj: ServiceForm) => (isEmpty(obj.description_et) && isEmpty(obj.description_ru)))},
      scheduleInfo_ru: {required: requiredIf((obj: ServiceForm) => (isEmpty(obj.scheduleInfo_et) && isEmpty(obj.scheduleInfo_en)))},
      scheduleInfo_et: {required: requiredIf((obj: ServiceForm) => (isEmpty(obj.scheduleInfo_ru) && isEmpty(obj.scheduleInfo_en)))},
      scheduleInfo_en: {required: requiredIf((obj: ServiceForm) => (isEmpty(obj.scheduleInfo_et) && isEmpty(obj.scheduleInfo_ru)))},
      rate: {
        type: {required},
        price: {
          amount: {
            required,
            nonNegativeCurrency: nonNegativeCurrencyValidator,
            ...buildValidationRules(DataBoundaries.currency),
            ...buildValidationRules(DataBoundaries.currencyNonNegative)
          },
          currency: {}
        }
      },
      languages: {required},
      location: locationValidation(),
      providedAtClientPlace: {},
      visible: {},
      uploadToken: {},
      agreeToPublishAddress: {sameAs: sameAs(() => true)},
      clientSex: {required},
      clientAge: {required},
      clientWeight: {required},
      clientMentalAbility: {required},
      clientPhysicalActivity: {required},
      clientDisease: {},
      joinToGroup: {}
    }
  }
}
