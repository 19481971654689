import {Component, Prop} from "vue-property-decorator";
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";
import {ServiceForm} from "@/_modules/service/types";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {ServiceComponentValidation} from "@/_modules/service/components/ServiceLayout";
import TextAreaInput from "@/components/form/TextAreaInput";
import RadioGroupInput from "@/components/form/RadioGroupInput";
import I18nOptions from "@/utils/I18nOptions";
import {RateType} from "@/types";
import TextInput from "@/components/form/TextInput";
import ToolTip from "@/components/layout/ToolTip";
import {calculatePriceWithPercentage} from "@/utils/formatters";
import {configStore, ServerCfg} from "@/store/config";
import * as MainRoutes from "@/router/routes";
import CheckBox from "@/components/form/CheckBox";
import {isEmpty} from "@/constants/DataBoundaries";
import {BIconCheckCircle, BIconXCircle} from "bootstrap-vue";


interface Props {
  readonly localeRoot: string;
  readonly value: ServiceForm;
  readonly v: ValidationInstance<ServiceComponentValidation>;
  readonly onCurrencyInputFormatted: (amount: string) => void;
}

@Component({name: 'ServiceStep4', components: {BIconCheckCircle, BIconXCircle}})
export default class ServiceStep4 extends TsxComponent<Props> {
  @Prop(String) public readonly localeRoot!: string
  @Prop(Object) public readonly value!: ServiceForm
  @Prop(Object) public readonly v!: ValidationInstance<ServiceComponentValidation>

  private serverConfig: ServerCfg | null = null

  public async mounted(): Promise<void> {
    await configStore.getServerConfig().then(resp => {
      if (typeof resp === "number") {
        console.error('Response code miss match: Expected 204, received: ' + resp)
        this.$router.push({
          name: MainRoutes.Error.name,
          params: {
            reason: 'msg_internal_server_error_config_is_not_defined'
          }
        })
      } else {
        this.serverConfig = resp
      }
    })
  }

  private formatCurrency(v: string): void {
    const formatted = v.replace(',', '.')
    this.$emit('currencyInputFormatted', formatted)
  }


  public render(): VNode {
    return (
      <div>
        <h2 class="fieldset-title mb-2">{this.translation(`${this.localeRoot}.wizard.title_conditions`)}</h2>
        <b-row class="multi-tab-textarea my-6 my-md-10">
          <b-col cols="12">
            <fieldset>
              <legend class="col-form-label py-0">{this.translation('fld_service_working_hours')}</legend>
              <p class="small">{this.translation('fld_service_working_hours_desc')}</p>
              <b-tabs nav-class="border-bottom-0">
                <b-tab>
                  <template slot="title">
                    {this.translation('text_EST')}
                    {!isEmpty(this.value.scheduleInfo_et) &&
                      <b-icon-check-circle aria-hidden="true" class="ml-2" variant="primary" />
                    }
                  </template>
                  <TextAreaInput
                    class="mb-4"
                    maxLength={2000}
                    textCounter
                    v-model={this.value.scheduleInfo_et}
                    invalid-message={this.buildInvalidMessage(this.v.form.scheduleInfo_et)}
                  />
                </b-tab>
                <b-tab>
                  <template slot="title">
                    {this.translation('text_RUS')}
                    {!isEmpty(this.value.scheduleInfo_ru) &&
                      <b-icon-check-circle aria-hidden="true" class="ml-2" variant="primary" />
                    }
                  </template>
                  <TextAreaInput
                    class="mb-4"
                    maxLength={2000}
                    textCounter
                    v-model={this.value.scheduleInfo_ru}
                    invalid-message={this.buildInvalidMessage(this.v.form.scheduleInfo_ru)}
                  />
                </b-tab>
                <b-tab>
                  <template slot="title">
                    {this.translation('text_ENG')}
                    {!isEmpty(this.value.scheduleInfo_en) &&
                      <b-icon-check-circle aria-hidden="true" class="ml-2" variant="primary" />
                    }
                  </template>
                  <TextAreaInput
                    class="mb-4"
                    maxLength={2000}
                    textCounter
                    v-model={this.value.scheduleInfo_en}
                    invalid-message={this.buildInvalidMessage(this.v.form.scheduleInfo_en)}
                  />
                </b-tab>
              </b-tabs>
            </fieldset>
          </b-col>
        </b-row>
        <RadioGroupInput
          class="mb-3"
          options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(RateType), 'enum_services_rate_type_')}
          v-model={this.value.rate.type}
          invalid-message={this.buildInvalidMessage(this.v.form.rate.type)}
        />
        <b-row>
          <b-col cols="12">
            <TextInput
              label={`${this.translation('fld_service_price')}*`}
              placeholder={this.translation('fld_service_price_placeholder')}
              value={this.value.rate.price.amount}
              onInput={(v: string) => this.formatCurrency(v)}
              invalid-message={this.buildInvalidMessage(this.v.form.rate.price.amount)}
              custom-class="price-input border-right-0"
              append={(): VNode => {
                return (
                  <div class="input-group-text pl-0">{this.translation(`enum_currency_${this.value.rate.price.currency}`)}</div>
                )
              }}
            />
          </b-col>
          <b-col cols="12">
            <div class="mb-6">
              <span>{this.translation('fld_service_price_for_requester')}</span>&nbsp;
              <ToolTip
                options={{title: this.translation(`tooltip_msg_service_price_for_requester_${this.value.rate.type}`)}}
                variant={'primary'}
              />
              <br/>
              <span class="font-weight-bold">{calculatePriceWithPercentage(this.serverConfig, this.value.rate.price)} {this.translation(`enum_currency_${this.value.rate.price.currency}`)}</span>
            </div>
            <CheckBox
              class="mb-3"
              checked={!this.value.visible}
              onChange={(v: boolean) => {
                this.value.visible = !v;
              }}
            >
              <template slot='label'>
                {this.translation('fld_hide_service')}&nbsp;
                <ToolTip
                  variant="primary"
                  options={{title: this.translation('text_tooltip_hide_service')}}
                />
              </template>
            </CheckBox>
          </b-col>
        </b-row>
      </div>
    )
  }
}
