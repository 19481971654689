import {Component, Watch} from "vue-property-decorator"
import {VNode} from 'vue'
import VueMultiSelect from "vue-multiselect"

import FormMixin from "@/mixins/FormMixin"
import CheckBox from "@/components/form/CheckBox"
import Button from "@/components/form/Button"
import AttentionPopup from "@/components/layout/AttentionPopup"
import PageHeader from "@/components/layout/PageHeader"
import LinkedCard from "@/components/layout/LinkedCard"
import {BIcon, BIconArrowLeft, BIconArrowRight, BIconPlusCircle, BIconChatDots, BIconGeoAlt, BIconGeoAltFill, BIconStar, BIconPatchQuestionFill, BIconSortDown, BIconSortDownAlt, BIconTag, BIconChevronBarLeft, BIconChevronBarRight} from "bootstrap-vue"

import {GmapCoordinates, MapInfoPanelData, mapStore, pageSize, SearchForm} from "@/store/map"
import {getServiceTranslationPropertyName, getServiceTypesSorted, getServiceTypeTranslation, getServiceTypeTranslationByKey, servicesStore} from "../store/services"
import {profileStore} from "@/_modules/profile/store/profile"
import {appStore, authStore} from "@/store"

import * as ServiceRoutes from "../router/routes"
import * as DemandRoutes from "../../demand/router/routes"
import * as ProfileRoutes from "../../profile/router/routes"

import ApplicationConfiguration, {defaultZoom, foundZoom} from "@/constants/ApplicationConfiguration"
import {calculateZoomRadius, coordinatesFromDistanceBearing, coordsToLatLng, getMapBoundsPadding, latLngToCoords, mapStyles} from "@/utils/map-utils"
import {ServiceCard, ServiceCriteria, ServiceFilterFields, ServiceType, ServiceTypeForSearch} from "@/_modules/service/types"
import {ArrayPage, buildFilters, GeoLocation, Language, MapCoordinates, OrderDirection, Page, RateType, SortFields} from "@/types"

import {distanceFormatter, getAllSubCategories, getRelativeSubCategories} from "@/utils/formatters"

import I18nOptions from "@/utils/I18nOptions"
import {isEmpty} from "@/constants/DataBoundaries"
import _ from "lodash";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import GmapCluster from 'gmap-vue/dist/components/cluster'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import * as GmapCircle from 'gmap-vue/dist/components/circle'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import GmapCustomMarker from 'vue2-gmap-custom-marker/gmap-custom-marker';
import {buildServiceFindForm, ServiceFindForm} from "@/_modules/service/form-builder";
import Cta from '@/components/layout/Cta';
import SubCategorySelectModal from "@/components/form/SubCategorySelectModal"
import FindServiceFilterModal from "@/_modules/service/components/FindServiceFilterModal"
import AppIconVerified from "@/components/icons/AppIconVerified"
import AppIconStar from "@/components/icons/AppIconStar"
import AppIconGenderAmbiguous from "@/components/icons/AppIconGenderAmbiguous"
import LoadingIndicator from "@/components/LoadingIndicator"

const serverPagination: Page = {
  current: 1,
  size: 100
}

@Component({name: 'FindService', components: {BIcon, BIconArrowLeft, BIconArrowRight, BIconPlusCircle, BIconChatDots, BIconChevronBarLeft, BIconChevronBarRight, BIconPatchQuestionFill, BIconStar, BIconGeoAlt, BIconGeoAltFill, BIconSortDown, BIconSortDownAlt, BIconTag}})
export default class extends FormMixin {

  public renderAttention: boolean = false

  public dragCenter: MapCoordinates = mapStore.lastPosition

  public form: ServiceFindForm = buildServiceFindForm()

  public mapInfoPanelData: MapInfoPanelData = {
    position: mapStore.lastPosition,
    visible: false,
    options: {
      pixelOffset: {
        width: 0,
        height: -26
      }
    }
  }

  public selectedCardId: string | null = null
  public selectedMarkerItem: ServiceCard | null = null
  public items: ServiceCard[] = []
  public fullwidthMap: boolean = false
  public fetchOnMapMove: boolean = true
  public disableMapEvents: boolean = false
  private loaded: boolean = false

  @Watch('mapInfoPanelData.visible')
  public async updateMapDataOnCenterShift(): Promise<void> {
    const req = await setTimeout(() => {
      if (this.mapInfoPanelData.visible && !_.isEqual(this.form.center, this.dragCenter)) {
        this.updateMapData()
      } else {
        clearTimeout(req)
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 1000)
  }

  @Watch('form.page.current')
  public scrollToFirstCard(): void {
    setTimeout(() => {
      const firstItemInList = document.getElementsByClassName('user-card')[0]
      firstItemInList.scrollIntoView({behavior: "smooth", block: "start"})
    })
  }

  public async onServiceTypeChange(): Promise<void> {
    await this.fetchServices()
  }

  private get serviceTypeOptions(): ServiceType[] {
    return getServiceTypesSorted(appStore.locale, false)
  }

  private get serviceTypeCriteria(): string {
    switch (this.form.serviceAtHome) {
      case ServiceTypeForSearch.All:
        return ServiceTypeForSearch.All
      case ServiceTypeForSearch.ServiceAtHome:
        return 'true'
      case ServiceTypeForSearch.NotServiceAtHome:
        return 'false'
      default:
        return ServiceTypeForSearch.All
    }
  }

  private async fetchServices(): Promise<void> {
    const filters = buildFilters<ServiceFilterFields>([
      [ServiceFilterFields.ONLINE_ONLY, this.form.online],
      [ServiceFilterFields.DISTANCE, this.form.distance],
      [ServiceFilterFields.SPEAKLANG, this.form.speakLang],
      [ServiceFilterFields.AGE, this.form.age],
      [ServiceFilterFields.WEIGHT, this.form.weight],
      [ServiceFilterFields.SEX, this.form.sex],
      [ServiceFilterFields.DISEASE, this.form.disease],
      [ServiceFilterFields.PHYSICAL, this.form.physical],
      [ServiceFilterFields.MENTAL, this.form.mental]
    ])
    if (this.form.serviceTypeId.length === 0 && !isEmpty(this.form.topLevelCategory)) {
      filters.set(ServiceFilterFields.CATEGORY, this.form.topLevelCategory)
    }
    if (this.form.serviceTypeId.length > 0) {
      filters.set(ServiceFilterFields.CATEGORY, this.form.serviceTypeId)
    }
    if (this.form.serviceAtHome !== ServiceTypeForSearch.All) {
      filters.set(ServiceFilterFields.SERVICE_AT_HOME, this.serviceTypeCriteria)
    }

    const criteria: ServiceCriteria = {
      order: this.form.order,
      pagination: serverPagination,
      coordinates: this.form.center,
      filters: filters,
      existLocationCords: isEmpty(this.form.location) ? undefined : this.form.existLocationCords
    }

    const resp = await this.withRequest(servicesStore.getOffers(criteria))

    this.items = resp.items
    this.form.page.current = 1
    this.form.totalNumberServices = resp.items.length

    const searchForm: SearchForm<SortFields> = {
      age: this.form.age,
      weight: this.form.weight,
      sex: this.form.sex,
      disease: this.form.disease,
      physical: this.form.physical,
      mental: this.form.mental,
      topLevelCategory: this.form.topLevelCategory,
      category: this.form.serviceTypeId,
      online: this.form.online,
      location: this.form.location,
      speakLang: this.form.speakLang,
      order: {
        columnName: this.form.order.columnName,
        orderDirection: this.form.order.orderDirection
      },
      serviceAtHome: this.form.serviceAtHome,
      center: this.form.center,
      zoom: this.form.zoom,
      distance: this.form.distance,
      existLocationCords: this.form.existLocationCords,
      page: new ArrayPage(1, pageSize)
    }
    
    mapStore.setServiceSearchData(searchForm)
  }

  private async updateMapData(): Promise<void> {
    const distance = await calculateZoomRadius(this.$refs.mapRef)
    if (distance !== undefined && this.fetchOnMapMove) {
      this.form.distance = distance

      await this.fetchServices()
    }
  }

  private async getLocation(): Promise<GeoLocation | undefined> {
    try {
      return authStore.authInfo === undefined ? undefined : (await profileStore.getSettings()).profile.location
    } catch (e) {
      return undefined
    }
  }

  public async mounted(): Promise<void> {
    const searchServicePreviousData = mapStore.searchData as SearchForm<SortFields>
    const location = await this.getLocation()

    this.form = {
      ...buildServiceFindForm(),
      ...searchServicePreviousData,
      serviceTypeId: searchServicePreviousData.category
    }

    if (location !== undefined) {
      this.form.location = searchServicePreviousData.location !== undefined ? searchServicePreviousData.location : location.address.address
      this.form.zoom = searchServicePreviousData.zoom !== undefined ? searchServicePreviousData.zoom : foundZoom
      this.form.center = searchServicePreviousData.center !== undefined ? searchServicePreviousData.center : location.coordinates
      this.form.existLocationCords = {
        dLon: location.coordinates.longitude,
        dLat: location.coordinates.latitude
      }
    } else {
      this.form.location = searchServicePreviousData.location ? searchServicePreviousData.location : ''
      this.form.zoom = searchServicePreviousData.zoom ? searchServicePreviousData.zoom : defaultZoom
      this.form.center = searchServicePreviousData.center ? searchServicePreviousData.center : mapStore.lastPosition
    }

    if (searchServicePreviousData.existLocationCords.dLat !== undefined) {
      this.form.existLocationCords = {
        dLat: searchServicePreviousData.existLocationCords.dLat,
        dLon: searchServicePreviousData.existLocationCords.dLon
      }
    }

    if (this.$route.query.topLevelCategory !== undefined) {
      this.form.topLevelCategory = this.$route.query.topLevelCategory.toString();
    }
    
    if (!isEmpty(this.form.topLevelCategory)) {
      this.$nextTick(async () => {
        const distance = await calculateZoomRadius(this.$refs.mapRef)
        if (distance !== undefined) {
          this.form.distance = distance
        }

        await this.fetchServices();
      })
    }

    this.loaded = true
  }

  private async onServiceClicked(e: Event, id: string): Promise<void> {
    e.preventDefault()
    const center = this.form.center
    const location = this.form.existLocationCords

    if (authStore.authInfo) {
      if (isEmpty(this.form.location)) {
        await this.$router.push({
          path: `${ServiceRoutes.ServiceDetails.path}/${id}`,
          query: {
            longitude: String(center.longitude),
            latitude: String(center.latitude)
          }
        })
      } else {
        await this.$router.push({
          path: `${ServiceRoutes.ServiceDetails.path}/${id}`,
          query: {
            longitude: String(location.dLon),
            latitude: String(location.dLat)
          }
        })
      }
    } else {
      this.renderAttention = true
    }
  }

  private onRenderDetails(item: ServiceCard): void {
    this.selectedCardId = item.service.id
    this.selectedMarkerItem = item
    this.mapInfoPanelData.position = item.service.coordinates
    this.dragCenter = item.service.coordinates //
    this.mapInfoPanelData.visible = true
    this.renderListItemInfo(item, false)

    const desktopScreenMinWidth = 992;
    if (window.innerWidth < desktopScreenMinWidth) {
      this.fullwidthMap = true;
    }
  }

  private onHideDetailsPopup(keepSelected: boolean): void {
    if (!keepSelected) {
      this.mapInfoPanelData.visible = false
      this.selectedMarkerItem = null
      this.selectedCardId = null
    }
  }

  private async onLocationChanged(place: google.maps.places.PlaceResult): Promise<void> {
    const location = place.geometry?.location
    if (location !== undefined && place.formatted_address !== undefined) {
      this.form.location = place.formatted_address
      this.form.center = latLngToCoords(location)
      this.form.zoom = foundZoom
      this.form.existLocationCords = {
        dLat: location.lat(),
        dLon: location.lng()
      }
      await this.updateMapData()
    }
  }

  private async onZoomChanged(zoom: number): Promise<void> {
    if (this.form.zoom !== zoom && !this.disableMapEvents) {
      this.form.zoom = zoom
      //this.mapInfoPanelData.visible = false
      await this.updateMapData()
    }
  }

  // Calculate suitable zoom level so that all markers in cluster stay inside the distance circle.
  private async onMapClusterClick(cluster: GmapCluster): Promise<void> {
    this.disableMapEvents = true;
    const markerClusterer = cluster.getMarkerClusterer();
    const map = markerClusterer.getMap();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const circle = await ((this.$refs.distanceRadius as any).$circlePromise as Promise<GmapCircle>);

    // const clusterCenter = cluster.getCenter();
    const mapCenter = circle.getCenter();
    const mapCenterLiteral: GmapCoordinates = {
      lat: mapCenter.lat(),
      lng: mapCenter.lng()
    }
    // Remove circle from map until zooming is done. Fixes issues with circle being blurry if zooming multiple zoom levels at once
    circle.setMap(null);

    // North-East bearing from center in degrees
    const northEastBearing = 45;
    // Get North-East point coordinates on circle (furthest point from map edge that is located on the edge of distance circle)
    const northEastCoordinatesOnCircle = coordinatesFromDistanceBearing(mapCenterLiteral, this.form.distance, northEastBearing);
    // Get padding in px for fitBounds
    const boundsPadding: google.maps.Padding = getMapBoundsPadding(map, northEastCoordinatesOnCircle);

    const clustererMaxZoom = markerClusterer.getMaxZoom();
    const clusterBounds = cluster.getBounds();

    (markerClusterer.getMap() as google.maps.Map).fitBounds(clusterBounds, boundsPadding);

    // Fix for issue 170 with googlemaps/js-markerclustererplus
    setTimeout(() => {
      // Add circle back
      circle.setMap(map);
      (markerClusterer.getMap() as google.maps.Map).fitBounds(clusterBounds, boundsPadding);
      // Don't zoom beyond the max zoom level
      if (clustererMaxZoom !== null && markerClusterer.getMap().getZoom() > clustererMaxZoom) {
        markerClusterer.getMap().setZoom(clustererMaxZoom + 1);
      }

      if (this.form.zoom !== map.getZoom()) {
        this.form.zoom = map.getZoom()
      }

      this.disableMapEvents = false;
      this.onCenterChanged(map.getCenter());
      this.onDragEnd();
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 100);
  }

  private onCenterChanged(center: google.maps.LatLng): void {
    this.dragCenter = latLngToCoords(center)
  }

  private async onDragEnd(): Promise<void> {
    if (!this.disableMapEvents) {
      if (this.fetchOnMapMove) {
        this.form.center = this.dragCenter
      }

      await this.updateMapData()
    }
  }

  private toDemandCreate(): void {
    if (authStore.authInfo) {
      this.$router.push({
        path: DemandRoutes.DemandNew.path
      })
    } else {
      this.renderAttention = true
    }
  }

  private formattedAttentionMessage(): VNode {
    return (
      <i18n tag="p" path="msg_unauthorized_user" />
    )
  }

  public markerLocation(): GmapCoordinates {
    if (isEmpty(this.form.location)) {
      return {
        lat: this.form.center.latitude, lng: this.form.center.longitude
      }
    } else {
      return {
        lat: this.form.existLocationCords.dLat!, lng: this.form.existLocationCords.dLon!
      }
    }
  }

  public distanceCircleLabelCoordinates(): GmapCoordinates {
    const markerLocation = this.markerLocation();
    // Calculate coordinates to position distance circle label, bearing 90 degrees for East direction
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const pointOnCircle = coordinatesFromDistanceBearing(markerLocation, this.form.distance, 90);

    // Use marker latitude for distance label. Otherwise label will be slightly offset depending on zoom level / distance.
    pointOnCircle.lat = markerLocation.lat;
    return pointOnCircle;
  }

  private renderListItemInfo(item: ServiceCard, isListItem: boolean): VNode {
    //let keepSelected = false
    return (
      <b-col class="mb-2 mb-sm-4" cols="12" sm="6" lg="12" xl="6">
        <b-card
          class={['offer-card h-100 shadow-sm rounded', item.service.id === this.selectedCardId ? 'selected' : '']}
          body-class="p-6"
          /*onClick={() => (keepSelected = true)}
          onMouseenter={() => this.onRenderDetails(item)}
          onMouseleave={() => this.onHideDetailsPopup(keepSelected)}*/
        >
          {isListItem && 
            <b-link 
              class="stretched-link"
              onClick={() => {
                this.onHideDetailsPopup(false)
                this.onRenderDetails(item)
              }}
              aria-label={this.translation('lbl_show_on_map')}
            />
          }
          {this.renderItemInfo(item, isListItem)}
        </b-card>
      </b-col>
    )
  }

  private renderItemInfo(item: ServiceCard, list: boolean): VNode {
    return (
      <div class={list ? 'offer-card-content d-flex flex-column h-100' : 'offer-card-content'}>
        <h3 class="mb-4">
          <b-link onClick={async (e: Event) => {await this.onServiceClicked(e, item.service.id)}}>
            {item.service.title}
          </b-link>
        </h3>
        <div class="d-flex mb-3">
          <div class="flex-grow-0 mr-3">
            <b-icon icon={item.service.serviceAtHome ? 'geo-alt-fill' : 'geo-alt'} class={`app-icon-lg align-top mt-0${item.service.serviceAtHome ? ' text-success' : ' text-gray-500'}`} aria-label={this.translation('lbl_distance')} />
          </div>
          {distanceFormatter(item.distanceInMeters, this.translation('text_m'), this.translation('text_km'))}. {item.service.serviceAtHome ? this.translation('msg_service_at_requester_location') : this.translation('msg_service_at_provider_location')}
        </div>
        <div class="d-flex mb-3">
          <div class="flex-grow-0 mr-3">
            <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-label={this.translation('lbl_languages')} />
          </div>
          <div>
            {item.service.languages.map((lang, index) => {
              return (
                lang === Language.ASE ?
                  <span>
                    <b-link class="text-primary" v-b-tooltip={{title: this.translation('enum_language_user_speak_ase')}}>
                      {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}
                    </b-link>{index !== item.service.languages.length - 1 ? ', ' : ''}
                  </span> :
                  <span>
                    {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}{index !== item.service.languages.length - 1 ? ', ' : ''}
                  </span>
              )
            })}
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="flex-grow-0 mr-3">
            <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-label={this.translation('lbl_sex')} />
          </div>
          {this.translation(`enum_sex_type_${item.provider.sex?.toLowerCase()}`)}
        </div>
        <div class="d-flex mb-3">
          <div class="flex-grow-0 mr-3">
            <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-label={this.translation('lbl_service_price')} />
          </div>
          <span class="font-weight-bold">
            {item.service.rate.price.amount} {this.translation(`enum_currency_${item.service.rate.price.currency}`)}{item.service.rate.type === RateType.HOURLY_PAYMENT ? this.translation('text_hour_price') : ''}
          </span>
        </div>
        <hr class="w-100 mt-auto mb-3" />
        <div class="d-flex justify-content-between flex-wrap">
          <div class="ellipsis-string-container flex-fill font-weight-bold mr-4">
            <span v-b-tooltip={{title: item.provider.displayName.length > ApplicationConfiguration.nameCropMidLength ? `${item.provider.displayName}` : ''}}>
              {item.provider.displayName}
              {item.provider.online && 
                <i class="online-indicator-dot" v-b-tooltip={{title: this.translation('lbl_online_indicator'), variant: 'primary'}}></i>
              }
            </span>
          </div>
          <div>
            <b-link class="mr-4" v-b-tooltip={{title: this.translation(`msg_param_verified_${item.provider.verified}`), variant: item.provider.verified ? 'primary' : 'danger-dark'}}>
              {item.provider.verified &&
                <AppIconVerified aria-hidden="true" class="app-icon-sm text-success" />
              }
              {!item.provider.verified &&
                <b-icon-patch-question-fill aria-hidden="true" class="app-icon-sm" variant="danger-dark" />
              }
            </b-link>
            {item.provider.rating.ratingsCount !== 0 &&
              <span class="small">
                <AppIconStar class="text-warning app-icon-sm mr-2" aria-label={this.translation('lbl_rating')} />
                {item.provider.rating.grade} ({item.provider.rating.ratingsCount})
              </span>
            }
            {item.provider.rating.ratingsCount === 0 &&
              <span class="small">
                <b-icon-star class="text-gray-400 app-icon-sm" aria-label={this.translation('lbl_no_rating')} />
              </span>
            }
          </div>
        </div>
      </div>
    )
  }

  private get onShowDetailsPopup(): boolean {
    return this.mapInfoPanelData.visible
  }

  public render(): VNode {
    return (
      <main class="bg-white">
        {!this.loaded && <div class="d-flex"><LoadingIndicator /></div>}
        {this.loaded &&
        <div>
          <b-container fluid="xl" class="service-find">
            <b-row>
              <PageHeader 
                title={this.translation('title_map-service')}
                wideHeader={true}
              >
                {isEmpty(this.form.topLevelCategory) && this.translation('lead_map-service')}
              </PageHeader>
            </b-row>
            {isEmpty(this.form.topLevelCategory) && 
              <b-row class="mb-12 mb-sm-10 mb-md-16 row-sm-narrow">
                {getServiceTypesSorted(appStore.locale, false).map((type, index) => {
                  return <b-col cols="12" sm={index === 0 ? '12' : '6'} md={index <= 1 ? '6' : '4'}>
                    <LinkedCard 
                      cardImage={require(`@/assets/images/Service/${type.category.toLowerCase()}.jpg`)}
                      title={getServiceTypeTranslation(appStore.locale, type)}
                      onClick={(event) => {
                        event.preventDefault();
                        this.form.topLevelCategory = type.category;

                        // Distance can't be calculated before the map is rendered on page
                        this.$nextTick(async () => {
                          const distance = await calculateZoomRadius(this.$refs.mapRef)
                          if (distance !== undefined) {
                            this.form.distance = distance
                          }
    
                          await this.fetchServices()
                        })
                      }}
                    />
                  </b-col>
                })}
              </b-row>
            }
            {!isEmpty(this.form.topLevelCategory) &&
              <div>
                <b-form-row>
                  <b-col cols="12" md="4">
                    <b-form-group>
                      <VueMultiSelect
                        class="custom-control-single-select font-weight-bold"
                        multiple={false}
                        close-on-select={true}
                        preselect-first={true}
                        searchable={false}
                        show-labels={false}
                        placeholder=""
                        options={this.serviceTypeOptions}
                        value={this.serviceTypeOptions.find(item => item.category === this.form.topLevelCategory)}
                        track-by="category"
                        label={getServiceTranslationPropertyName(appStore.locale)}
                        onInput={(value: ServiceType) => {
                          this.form.topLevelCategory = value.category;
                          this.form.serviceTypeId = []
                          this.onServiceTypeChange()
                        }}
                        scopedSlots={{
                          singleLabel: ({ option }: { option: ServiceType }): VNode => {
                            return (
                              <span>{this.translation('fld_filter_service_top_level_category_prefix')}: {getServiceTypeTranslationByKey(appStore.locale, option.category)}</span>
                            )
                          }
                        }}
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <SubCategorySelectModal
                      options={isEmpty(this.form.topLevelCategory) ? getAllSubCategories(appStore.locale) : getRelativeSubCategories(this.form.topLevelCategory, appStore.locale)}
                      value={this.form.serviceTypeId}
                      onConfirm={(v: string[]) => {
                        this.form.serviceTypeId = v
                        this.onServiceTypeChange()
                      }}
                      disabled={isEmpty(this.form.topLevelCategory)}
                      buttonLabel={isEmpty(this.form.topLevelCategory) ? this.translation('shared.select_top_category') : this.translation('fld_filter_by_service_type')}
                      modalTitle={this.translation('modal_title_choose_service_types', [getServiceTypeTranslationByKey(appStore.locale, this.form.topLevelCategory)])}
                    />
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group>
                      <gmap-autocomplete
                        class="form-control font-weight-bold placeholder-dark"
                        value={this.form.location}
                        placeholder={this.translation('fld_service_location')}
                        onplace_changed={this.onLocationChanged}
                        select-first-on-enter={true}
                        onChange={(e: InputEvent) => {
                          if (e.data === null || e.data === undefined) {
                            this.form.location = ''
                          }
                        }}
                        onBlur={() => this.fetchServices()}
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-column flex-md-row flex-md-wrap mb-4">
                      <b-form-group class="mr-md-6">
                        <VueMultiSelect
                          class="custom-control-single-select"
                          multiple={false}
                          close-on-select={true}
                          preselect-first={true}
                          searchable={false}
                          show-labels={false}
                          placeholder=""
                          options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(OrderDirection), 'enum_cost_order_direction_')}
                          value={I18nOptions.buildEnumOptions(this.$i18n, Object.values(OrderDirection), 'enum_cost_order_direction_').find((direction) => direction.value === this.form.order.orderDirection)}
                          track-by="value"
                          label="text"
                          onInput={({ value }: { value: OrderDirection }) => {
                            this.form.order.orderDirection = value;
                            this.onServiceTypeChange()
                          }}
                          scopedSlots={{
                            singleLabel: ({ option }: { option: { value: string; text: string } }): VNode => {
                              return (
                                <span class="mr-6"><b-icon icon={option.value === OrderDirection.ASC ? 'sort-down-alt' : 'sort-down'} variant="primary" class="mr-2"/>{ this.translation(`enum_cost_order_direction_${option.value}`) }</span>
                              )
                            }
                          }}
                        />
                      </b-form-group>
                      <CheckBox
                        class="d-flex align-items-center mr-md-6"
                        checkBoxClass="m-0"
                        v-model={this.form.online}
                        label={this.translation('fld_performer_available')}
                        onChange={() => {
                          this.fetchServices()
                        }}
                      />
                      <CheckBox
                        class="d-flex align-items-center mr-md-6"
                        checkBoxClass="m-0"
                        v-model={this.fetchOnMapMove}
                        label={this.translation('fld_fetch_on_map_move')}
                      />
                      <FindServiceFilterModal 
                        value={this.form}
                        onConfirm={(value: ServiceFindForm) => {
                          this.form = Object.assign(this.form, value);
                          this.onServiceTypeChange()
                        }}
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            }
            <AttentionPopup
              message={this.formattedAttentionMessage}
              btnNextLabel={'btn_login'}
              btnNextPath={ProfileRoutes.Login.path}
              btnNext={true}
              btnNextAltLabel={'btn_register'}
              btnNextAltPath={ProfileRoutes.Signup.path}
              btnNextAlt={true}
              btnBack={false}
              btnClose={true}
              onCancel={() => {this.renderAttention = false}}
              show={this.renderAttention}
            />
          </b-container>
          {!isEmpty(this.form.topLevelCategory) &&
            <b-container fluid class="service-find-map-panel border-top border-bottom">
              <b-row class="overflow-hidden flex-nowrap justify-content-end justify-content-lg-start h-100">
                <b-col cols="12" lg="6" class="app-gmap-wrapper px-0 mb-4 mb-sm-6 mb-lg-0">
                  <b-link
                    class="app-gmap-resize d-none d-lg-block p-3"
                    aria-label={this.translation(this.fullwidthMap ? 'lbl_map_full_width' : 'lbl_map_normal_width')}
                    onClick={() => {
                      this.fullwidthMap = !this.fullwidthMap;
                    }}
                  >
                    {!this.fullwidthMap && 
                      <b-icon-chevron-bar-right class="app-icon-lg d-block m-0" />
                    }
                    {this.fullwidthMap && 
                      <b-icon-chevron-bar-left class="app-icon-lg d-block m-0" />
                    }
                  </b-link>
                  <gmap-map
                    center={coordsToLatLng(this.form.center)}
                    zoom={this.form.zoom}
                    class={`h-100${this.fullwidthMap ? ' app-gmap-fullwidth' : ''}`}
                    ref="mapRef"
                    options={{
                      scrollwheel: false,
                      minZoom: 6,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      styles: mapStyles
                    }}
                    ondragend={this.onDragEnd}
                    onzoom_changed={(v: number) => this.onZoomChanged(v)}
                    oncenter_changed={this.onCenterChanged}
                    onсlick={this.onHideDetailsPopup}
                  >
                    <gmap-info-window
                      options={this.mapInfoPanelData.options}
                      position={coordsToLatLng(this.mapInfoPanelData.position)}
                      opened={this.onShowDetailsPopup}
                      onCloseclick={() => this.onHideDetailsPopup(false)}
                      style={{maxWidth: 650, maxHeight: 500}}
                    >
                      {this.selectedMarkerItem !== null && this.renderItemInfo(this.selectedMarkerItem, false)}
                    </gmap-info-window>
                    <GmapCluster
                      zoomOnClick={false}
                      ref="clusterRef"
                      onClick={(cluster: GmapCluster) => {
                        this.onMapClusterClick(cluster);
                      }}
                      styles={[
                        {
                          height: 46,
                          width: 60,
                          className: "app-gmap-cluster"
                        }
                      ]}
                    >
                      {this.items.map(item => {
                        return (
                          <GmapCustomMarker
                            alignment="center"
                            marker={coordsToLatLng(item.service.coordinates)}
                          >
                            <b-badge
                              onClick={() => this.onRenderDetails(item)}
                              href="#"
                              class="app-gmap-custom-marker"
                              variant="primary"
                              pill
                            >
                              {distanceFormatter(item.distanceInMeters, this.translation('text_m'), this.translation('text_km'))}
                            </b-badge>
                          </GmapCustomMarker>
                        )
                      })}
                    </GmapCluster>
                    <GmapCustomMarker
                      alignment="center"
                      marker={this.markerLocation()}
                    >
                      <b-img src={require('@/assets/images/icons/marker_home.svg')} />
                    </GmapCustomMarker>
                    <GmapCustomMarker
                      marker={this.distanceCircleLabelCoordinates()}
                      alignment="center"
                    >
                      <b-badge variant="gray-500" class="app-gmap-distance-label">{distanceFormatter(this.form.distance, this.translation('text_m'), this.translation('text_km'))}</b-badge>
                    </GmapCustomMarker>
                    <gmap-circle
                      ref="distanceRadius"
                      radius={this.form.distance}
                      center={this.markerLocation()}
                      options={{
                        fillOpacity:"0",
                        strokeColor:"#808080",
                        strokeWeight:"1",
                        clickable: false
                      }}
                    />
                  </gmap-map>
                </b-col>
                <b-col cols="12" lg="6" class={`cards-with-pagination mb-2${this.fullwidthMap ? ' d-none d-lg-block offset-lg-6' : ''}`}>
                  {this.items.length === 0 &&
                    <b-row class="align-items-center justify-content-center h-100">
                      <div class="my-16 p-6 d-flex flex-column align-items-center justify-content-center no-results-found">
                        <p class="mb-1 h4">{this.translation('msg_nothing_found')}</p>
                        <p class="small mb-0">{this.translation('msg_nothing_found_change_filters')}</p>
                      </div>
                    </b-row>
                  }
                  {this.items.length > 0 && 
                    <b-row id="user-cards" class="row-sm-narrow pt-4">
                      {this.form.page.paginate(this.items).map(item => this.renderListItemInfo(item, true))}
                    </b-row>
                  }
                  {this.items.length > this.form.page.size &&
                    <div class="overflow-auto">
                      <b-pagination
                        v-model={this.form.page.current}
                        pills
                        align="center"
                        total-rows={this.form.totalNumberServices}
                        per-page={this.form.page.size}
                        first-number
                        last-number
                        aria-controls="user-cards"
                        class="mt-4 mt-sm-2 mt-md-6 mb-2 mb-sm-4"
                      >
                        <template slot="prev-text"><b-icon-arrow-left /></template>
                        <template slot="next-text"><b-icon-arrow-right /></template>
                      </b-pagination>
                    </div>
                  }
                </b-col>
              </b-row>
              <div class="app-gmap-toggle-wrap w-100 d-flex justify-content-center d-lg-none">
                <b-link
                  class="btn btn-primary mt-6 mt-sm-4 mb-10"
                  onClick={() => {
                    this.fullwidthMap = !this.fullwidthMap;
                  }}
                >
                  <b-icon-geo-alt class="app-icon-lg mr-2" />
                  {this.translation(this.fullwidthMap ? 'lbl_close_map' : 'lbl_map')}
                </b-link>
              </div>
            </b-container>
          }
          <Cta
            backgroundImage={require('@/assets/images/Service/cta_background.jpg')}
          >
            <h2>{this.translation('cta_title_map-service')}</h2>
            <p class="lead mb-6">{this.translation('cta_content_map-service')}</p>
            <Button
              onClick={() => this.toDemandCreate()}
              variant="primary"
              class="mr-2"
            >
              <template slot="label">
                <b-icon-plus-circle class="app-icon-lg mr-2"/>
                {this.translation('btn_add_vacancy')}
              </template>
            </Button>
          </Cta>
        </div>
        }
      </main>
    )
  }
}
